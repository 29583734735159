/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {DataAutomationPipelineRun} from './DataAutomationPipelineRun';

/**
 * The InlineResponse20027 model module.
 * @module model/InlineResponse20027
 * @version 1.0.0
 */
export class InlineResponse20027 {
  /**
   * Constructs a new <code>InlineResponse20027</code>.
   * @alias module:model/InlineResponse20027
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>InlineResponse20027</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/InlineResponse20027} obj Optional instance to populate.
   * @return {module:model/InlineResponse20027} The populated <code>InlineResponse20027</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new InlineResponse20027();
      if (data.hasOwnProperty('data'))
        obj.data = ApiClient.convertToType(data['data'], [DataAutomationPipelineRun]);
    }
    return obj;
  }
}

/**
 * @member {Array.<module:model/DataAutomationPipelineRun>} data
 */
InlineResponse20027.prototype.data = undefined;

