/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';

/**
 * The EmailsCheckSettingsImportEmails model module.
 * @module model/EmailsCheckSettingsImportEmails
 * @version 1.0.0
 */
export class EmailsCheckSettingsImportEmails {
  /**
   * Constructs a new <code>EmailsCheckSettingsImportEmails</code>.
   * @alias module:model/EmailsCheckSettingsImportEmails
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>EmailsCheckSettingsImportEmails</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/EmailsCheckSettingsImportEmails} obj Optional instance to populate.
   * @return {module:model/EmailsCheckSettingsImportEmails} The populated <code>EmailsCheckSettingsImportEmails</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new EmailsCheckSettingsImportEmails();
      if (data.hasOwnProperty('enabled'))
        obj.enabled = ApiClient.convertToType(data['enabled'], 'Boolean');
      if (data.hasOwnProperty('file'))
        obj.file = ApiClient.convertToType(data['file'], 'String');
    }
    return obj;
  }
}

/**
 * @member {Boolean} enabled
 */
EmailsCheckSettingsImportEmails.prototype.enabled = undefined;

/**
 * Allowed values for the <code>file</code> property.
 * @enum {String}
 * @readonly
 */
EmailsCheckSettingsImportEmails.FileEnum = {
  /**
   * value: "full"
   * @const
   */
  full: "full",

  /**
   * value: "verified"
   * @const
   */
  verified: "verified",

  /**
   * value: "active_verified"
   * @const
   */
  active_verified: "active_verified"
};
/**
 * @member {module:model/EmailsCheckSettingsImportEmails.FileEnum} file
 */
EmailsCheckSettingsImportEmails.prototype.file = undefined;

