/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {ActionsTriggerAutomationActionIdBody} from '../model/ActionsTriggerAutomationActionIdBody';
import {InlineResponse20021} from '../model/InlineResponse20021';
import {InlineResponse20113} from '../model/InlineResponse20113';
import {TriggersActionsBody} from '../model/TriggersActionsBody';

/**
* TriggerAutomationAction service.
* @module api/TriggerAutomationActionApi
* @version 1.0.0
*/
export class TriggerAutomationActionApi {

    /**
    * Constructs a new TriggerAutomationActionApi. 
    * @alias module:api/TriggerAutomationActionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addTriggerAutomationAction operation.
     * @callback moduleapi/TriggerAutomationActionApi~addTriggerAutomationActionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20113{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new trigger automation action
     * @param {module:model/TriggersActionsBody} body Trigger Automation Action
     * @param {module:api/TriggerAutomationActionApi~addTriggerAutomationActionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addTriggerAutomationAction(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addTriggerAutomationAction");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20113;

      return this.apiClient.callApi(
        '/automation/triggers/actions', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the clearTriggerAutomationActionData operation.
     * @callback moduleapi/TriggerAutomationActionApi~clearTriggerAutomationActionDataCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20113{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Clear trigger automation action data
     * @param {String} triggerAutomationActionId Trigger Automation Action ID
     * @param {module:api/TriggerAutomationActionApi~clearTriggerAutomationActionDataCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    clearTriggerAutomationActionData(triggerAutomationActionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationActionId' is set
      if (triggerAutomationActionId === undefined || triggerAutomationActionId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationActionId' when calling clearTriggerAutomationActionData");
      }

      let pathParams = {
        'triggerAutomationActionId': triggerAutomationActionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20113;

      return this.apiClient.callApi(
        '/automation/triggers/actions/{triggerAutomationActionId}/data/clear', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteTriggerAutomationAction operation.
     * @callback moduleapi/TriggerAutomationActionApi~deleteTriggerAutomationActionCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a trigger automation action
     * @param {String} triggerAutomationActionId Trigger Automation Action ID
     * @param {module:api/TriggerAutomationActionApi~deleteTriggerAutomationActionCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteTriggerAutomationAction(triggerAutomationActionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationActionId' is set
      if (triggerAutomationActionId === undefined || triggerAutomationActionId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationActionId' when calling deleteTriggerAutomationAction");
      }

      let pathParams = {
        'triggerAutomationActionId': triggerAutomationActionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/triggers/actions/{triggerAutomationActionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the generateTriggerAutomationActionData operation.
     * @callback moduleapi/TriggerAutomationActionApi~generateTriggerAutomationActionDataCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20113{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Generate trigger automation action data
     * @param {String} triggerAutomationActionId Trigger Automation Action ID
     * @param {module:api/TriggerAutomationActionApi~generateTriggerAutomationActionDataCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    generateTriggerAutomationActionData(triggerAutomationActionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationActionId' is set
      if (triggerAutomationActionId === undefined || triggerAutomationActionId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationActionId' when calling generateTriggerAutomationActionData");
      }

      let pathParams = {
        'triggerAutomationActionId': triggerAutomationActionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20113;

      return this.apiClient.callApi(
        '/automation/triggers/actions/{triggerAutomationActionId}/data/generate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomationAction operation.
     * @callback moduleapi/TriggerAutomationActionApi~getTriggerAutomationActionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20113{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a trigger automation action
     * @param {String} triggerAutomationActionId Trigger Automation Action ID
     * @param {module:api/TriggerAutomationActionApi~getTriggerAutomationActionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomationAction(triggerAutomationActionId, callback) {
      
      let postBody = null;
      // verify the required parameter 'triggerAutomationActionId' is set
      if (triggerAutomationActionId === undefined || triggerAutomationActionId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationActionId' when calling getTriggerAutomationAction");
      }

      let pathParams = {
        'triggerAutomationActionId': triggerAutomationActionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20113;

      return this.apiClient.callApi(
        '/automation/triggers/actions/{triggerAutomationActionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getTriggerAutomationActions operation.
     * @callback moduleapi/TriggerAutomationActionApi~getTriggerAutomationActionsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20021{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get trigger automation actions
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.type Type
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of trigger automation actions
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/TriggerAutomationActionApi~getTriggerAutomationActionsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getTriggerAutomationActions(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'type': opts['type'],'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20021;

      return this.apiClient.callApi(
        '/automation/triggers/actions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateTriggerAutomationAction operation.
     * @callback moduleapi/TriggerAutomationActionApi~updateTriggerAutomationActionCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20113{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a trigger automation action
     * @param {module:model/ActionsTriggerAutomationActionIdBody} body Trigger Automation Action
     * @param {String} triggerAutomationActionId Trigger Automation Action ID
     * @param {module:api/TriggerAutomationActionApi~updateTriggerAutomationActionCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateTriggerAutomationAction(body, triggerAutomationActionId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateTriggerAutomationAction");
      }
      // verify the required parameter 'triggerAutomationActionId' is set
      if (triggerAutomationActionId === undefined || triggerAutomationActionId === null) {
        throw new Error("Missing the required parameter 'triggerAutomationActionId' when calling updateTriggerAutomationAction");
      }

      let pathParams = {
        'triggerAutomationActionId': triggerAutomationActionId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20113;

      return this.apiClient.callApi(
        '/automation/triggers/actions/{triggerAutomationActionId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}