/*
 * Marketing API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0.0
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {AutomationDataBody} from '../model/AutomationDataBody';
import {DataDataAutomationIdBody} from '../model/DataDataAutomationIdBody';
import {InlineResponse20024} from '../model/InlineResponse20024';
import {InlineResponse20025} from '../model/InlineResponse20025';
import {InlineResponse20116} from '../model/InlineResponse20116';
import {InlineResponse20117} from '../model/InlineResponse20117';

/**
* DataAutomation service.
* @module api/DataAutomationApi
* @version 1.0.0
*/
export class DataAutomationApi {

    /**
    * Constructs a new DataAutomationApi. 
    * @alias module:api/DataAutomationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the addDataAutomation operation.
     * @callback moduleapi/DataAutomationApi~addDataAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20116{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Create a new data automation
     * @param {module:model/AutomationDataBody} body Data Automation
     * @param {module:api/DataAutomationApi~addDataAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    addDataAutomation(body, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling addDataAutomation");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20116;

      return this.apiClient.callApi(
        '/automation/data', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the deleteDataAutomation operation.
     * @callback moduleapi/DataAutomationApi~deleteDataAutomationCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete a data automation
     * @param {String} dataAutomationId Data Automation ID
     * @param {module:api/DataAutomationApi~deleteDataAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     */
    deleteDataAutomation(dataAutomationId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationId' is set
      if (dataAutomationId === undefined || dataAutomationId === null) {
        throw new Error("Missing the required parameter 'dataAutomationId' when calling deleteDataAutomation");
      }

      let pathParams = {
        'dataAutomationId': dataAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/automation/data/{dataAutomationId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomation operation.
     * @callback moduleapi/DataAutomationApi~getDataAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20116{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get a data automation
     * @param {String} dataAutomationId Data Automation ID
     * @param {module:api/DataAutomationApi~getDataAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomation(dataAutomationId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationId' is set
      if (dataAutomationId === undefined || dataAutomationId === null) {
        throw new Error("Missing the required parameter 'dataAutomationId' when calling getDataAutomation");
      }

      let pathParams = {
        'dataAutomationId': dataAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20116;

      return this.apiClient.callApi(
        '/automation/data/{dataAutomationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomationRuns operation.
     * @callback moduleapi/DataAutomationApi~getDataAutomationRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20025{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get data automation runs
     * @param {String} dataAutomationId Data automation id
     * @param {Object} opts Optional parameters
     * @param {module:model/String} opts.state State
     * @param {Number} opts.limit Number of data automation runs
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/DataAutomationApi~getDataAutomationRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomationRuns(dataAutomationId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'dataAutomationId' is set
      if (dataAutomationId === undefined || dataAutomationId === null) {
        throw new Error("Missing the required parameter 'dataAutomationId' when calling getDataAutomationRuns");
      }

      let pathParams = {
        'dataAutomationId': dataAutomationId
      };
      let queryParams = {
        'state': opts['state'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20025;

      return this.apiClient.callApi(
        '/automation/data/{dataAutomationId}/runs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getDataAutomations operation.
     * @callback moduleapi/DataAutomationApi~getDataAutomationsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20024{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get data automations
     * @param {Object} opts Optional parameters
     * @param {String} opts.keyword Keyword search in title and description
     * @param {Number} opts.limit Number of data automations
     * @param {String} opts.sort Field to sort by
     * @param {module:model/String} opts.order Sort order
     * @param {String} opts.start Sort skip for pagination
     * @param {module:api/DataAutomationApi~getDataAutomationsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getDataAutomations(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'keyword': opts['keyword'],'limit': opts['limit'],'sort': opts['sort'],'order': opts['order'],'start': opts['start']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20024;

      return this.apiClient.callApi(
        '/automation/data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the getRecentDataAutomationRuns operation.
     * @callback moduleapi/DataAutomationApi~getRecentDataAutomationRunsCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20025{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get recent data automation runs
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.dataAutomationIds Data Automation Ids
     * @param {module:api/DataAutomationApi~getRecentDataAutomationRunsCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    getRecentDataAutomationRuns(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'dataAutomationIds': this.apiClient.buildCollectionParam(opts['dataAutomationIds'], 'csv')
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20025;

      return this.apiClient.callApi(
        '/automation/data/runs/recent', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the runDataAutomation operation.
     * @callback moduleapi/DataAutomationApi~runDataAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20117{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Run a data automation
     * @param {String} dataAutomationId Data Automation ID
     * @param {module:api/DataAutomationApi~runDataAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    runDataAutomation(dataAutomationId, callback) {
      
      let postBody = null;
      // verify the required parameter 'dataAutomationId' is set
      if (dataAutomationId === undefined || dataAutomationId === null) {
        throw new Error("Missing the required parameter 'dataAutomationId' when calling runDataAutomation");
      }

      let pathParams = {
        'dataAutomationId': dataAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse20117;

      return this.apiClient.callApi(
        '/automation/data/{dataAutomationId}/run', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the updateDataAutomation operation.
     * @callback moduleapi/DataAutomationApi~updateDataAutomationCallback
     * @param {String} error Error message, if any.
     * @param {module:model/InlineResponse20116{ data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update a data automation
     * @param {module:model/DataDataAutomationIdBody} body Data Automation
     * @param {String} dataAutomationId Data Automation ID
     * @param {module:api/DataAutomationApi~updateDataAutomationCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link <&vendorExtensions.x-jsdoc-type>}
     */
    updateDataAutomation(body, dataAutomationId, callback) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateDataAutomation");
      }
      // verify the required parameter 'dataAutomationId' is set
      if (dataAutomationId === undefined || dataAutomationId === null) {
        throw new Error("Missing the required parameter 'dataAutomationId' when calling updateDataAutomation");
      }

      let pathParams = {
        'dataAutomationId': dataAutomationId
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse20116;

      return this.apiClient.callApi(
        '/automation/data/{dataAutomationId}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }

}